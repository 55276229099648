import {Link} from "gatsby";
import React from "react";

const cta = () => {
    return (
        <div className="bg-gray-300">
            <div
                className="container mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:flex lg:items-center lg:justify-between">
                <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                    Masz wątpliwości?
                    <br/>
                    <span className="text-main">Skontaktuj się z nami.</span>
                </h2>
                <div className="mt-8 flex lg:flex-shrink-0 lg:mt-0">
                    <div className="inline-flex rounded-md shadow">
                        <a href="tel:158329535"
                           className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-main hover:bg-black focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                            Zadzwoń
                        </a>
                    </div>
                    <div className="ml-3 inline-flex rounded-md shadow">
                        <Link to="/kontakt/"
                              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-main bg-white hover:text-black focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                            Kontakt
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default cta;