import React from "react"
import { graphql } from "gatsby"
import Cta from '../components/cta';

import Layout from "../components/layout"
import SEO from "../components/seo"

class BlogPostContentfulTemplate extends React.Component {

  render() {
    const post = this.props.data.contentfulPost;
    const siteTitle = this.props.data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.title}
          description={post.title}
        />
        <section className="mx-auto container md:w-1/2 mt-6">

            <div className="mb-5">
                { post.heroImage && (
                    <img className="rounded-lg" srcSet={post.heroImage.fluid.srcSet} alt={post.heroImage.title} />
                )}
            </div>

            <span className="leading-none tracking-wide font-semibold text-main align-baseline uppercase">{ post.category.title }</span>
            <h1 className="text-5xl tracking-tight font-extrabold leading-tight mb-4">{ post.title }</h1>

            <div className="flex items-center align-middle">
                <img srcSet={post.author.avatar.fluid.srcSet} className="w-6 h-6 border border rounded-full mr-2" alt={post.author.name} /> <span className="text-main mr-6">{ post.author.name }</span>
                <span className="text-gray-700 inline-flex items-center">
                    <svg className="w-4 h-4 text-gray-700 fill-current mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 443.294 443.294">
                      <path d="M221.647 0C99.433 0 0 99.433 0 221.647s99.433 221.647 221.647 221.647 221.647-99.433 221.647-221.647S343.861 0 221.647 0zm0 415.588c-106.941 0-193.941-87-193.941-193.941s87-193.941 193.941-193.941 193.941 87 193.941 193.941-87 193.941-193.941 193.941z"/>
                      <path d="M235.5 83.118h-27.706v144.265l87.176 87.176 19.589-19.589-79.059-79.059z"/>
                    </svg>
                    { post.createdAt }
                </span>
            </div>

            <section className="markdown" dangerouslySetInnerHTML={{ __html: post.content.childContentfulRichText.html }} />


        </section>
          <Cta />
      </Layout>
    )
  }
}

export default BlogPostContentfulTemplate

export const pageQuery = graphql`
  query ContentfulPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPost( slug: { eq: $slug }) {
      contentful_id
      title
      slug
      createdAt(formatString: "DD.MM.Y HH:mm")
      heroImage {
        fluid {
          srcSet
        }
      }
      category {
        title
        slug
      }
      content {
        childContentfulRichText {
          html
        }
      }
      author {
        slug
        name
        avatar {
          title
          fluid {
            srcSet
          }
        }
      }
    }
  }
`
